import React, { useEffect, useRef, useState } from 'react'
import { History } from 'history'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ExClient } from '../ExClient'
import { useStoreMain } from '../store/useStoreMain'
import { useStoreExchange } from '../store/useStoreExchange'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import {
  selectExchangeAmountLimit,
  selectPaymentTypes,
  selectSetStep1,
  selectStep1
} from '../store/selectors/ExchangePageSelectors'
import { getIsAuthorized } from '../store/selectors/MainSelectors'
import {
  selectCrypto,
  selectCryptoAmount,
  selectCryptoCurrency,
  selectFetchCurrencies, selectFiat,
  selectFiatAmount,
  selectFiatCurrency
} from '../store/selectors/ExchangeSelectors'
import { validateAmount, validateCryptoAmount, validateWallet } from '../helpers/ExchangeHelpers'
import { cfgExchange } from '../DefaultValues'
import { getIsEmailVerified, getIsRegistered, sendVerifyEmail } from '../store/apiRequests/exchangeRequests'
import { ExchangeSelector, ExchangeStep1Values, PaymentType } from '../types/ExchangeTypes'

type ExchangeStep1Props = {
  history: History
  msgFieldRequired?: string
  msgInvalidWallet?: string
  msgEnterValidWallet?: string
}

type ExchangeStep1Return = {
  isAuthorized: boolean
  isShowVerifyEmail: boolean
  formMethods: UseFormMethods<ExchangeStep1Values>
  onSubmit: (data: ExchangeStep1Values) => void
  onEmailVerified: () => void
  onEmailVerifyHide: () => void
  captchaRef: React.MutableRefObject<any>
  onCaptchaChange: (value: string) => void
  onSelectPayment: (paymentType: PaymentType) => void
  step1Values: ExchangeStep1Values
  paymentTypes: PaymentType[]
  selectFiat: ExchangeSelector
  selectCrypto: ExchangeSelector
  hasWallet: boolean
  paymentSelected: string
}

export const useExchangeStep1 = <TFormValues extends Record<string, any> = Record<string, any>>({
  history,
  msgFieldRequired = 'This field is required.',
  msgInvalidWallet = 'Invalid wallet!',
  msgEnterValidWallet = 'Please enter a valid wallet.'
}: ExchangeStep1Props): ExchangeStep1Return => {
  const isAuthorized = useStoreMain(getIsAuthorized)
  const fiatAmount = useStoreExchange(selectFiatAmount)
  const fiatCurrency = useStoreExchange(selectFiatCurrency)
  const cryptoAmount = useStoreExchange(selectCryptoAmount)
  const cryptoCurrency = useStoreExchange(selectCryptoCurrency)
  const setStep1 = useStoreExchangePage(selectSetStep1)
  const step1Values = useStoreExchangePage(selectStep1)
  const paymentTypes = useStoreExchangePage(selectPaymentTypes)
  const exchangeAmountLimit = useStoreExchangePage(selectExchangeAmountLimit)
  const fetchCurrencies = useStoreExchange(selectFetchCurrencies)

  const [hasWallet, setHasWallet] = useState(false)
  const [isShowVerifyEmail, setIsShowVerifyEmail] = useState(false)

  const { paymentType, email, wallet, agree_terms, agree_refund, agree_age } = step1Values

  const payment_type = ['visa', 'mastercard', 'maestro'].includes(paymentType.name) ? '' : paymentType.id
  const [paymentSelected, setPaymentSelected] = useState(payment_type)

  const formMethods = useForm<ExchangeStep1Values>({
    defaultValues: {
      email, wallet, agree_terms, agree_refund, agree_age
    }
  })
  const { clearErrors, setError, setValue, trigger } = formMethods

  useEffect(() => {
    if (!paymentType || !paymentType.id) return
    fetchCurrencies(paymentType.id)
  }, [paymentType, fetchCurrencies])

  useEffect(() => {
    const fiatErr = validateAmount(fiatAmount, msgFieldRequired)
    const cryptoErr = validateCryptoAmount(cryptoAmount, cryptoCurrency, exchangeAmountLimit, msgFieldRequired)

    clearErrors(['fiatAmount', 'cryptoAmount'])
    if (fiatErr !== '') setError('fiatAmount', { message: fiatErr })
    if (cryptoErr !== '') setError('cryptoAmount', { message: cryptoErr })
  }, [fiatAmount, cryptoAmount, cryptoCurrency, setError, clearErrors])

  useEffect(() => {
    if (!isAuthorized)
      return setHasWallet(false)

    const checkHasWallet = async () => {
      setHasWallet(await ExClient.hasWallet())
    }
    checkHasWallet().then()
  }, [isAuthorized])

  useEffect(() => {
    if (paymentSelected === '') return
    trigger('payment_type').then()
  }, [trigger, paymentSelected])

  const onSelectPayment = (paymentType: PaymentType) => {
    setStep1({ paymentType })
    setPaymentSelected(paymentType.id)
  }

  const onSubmit = async (data: ExchangeStep1Values) => {
    if (isAuthorized)
      await ExClient.hasWallet()

    const { email, wallet, captcha } = data
    if (wallet) {
      clearErrors('wallet')
      const walletErr = await validateWallet(isAuthorized, wallet, cryptoCurrency, fiatAmount, fiatCurrency, msgInvalidWallet, msgEnterValidWallet)
      if (walletErr !== '')
        return setError('wallet', { message: walletErr })
    }

    setStep1(data)
    if (isAuthorized)
      return history.push(cfgExchange.STEPS.STEP3.route)

    const isRegistered = await getIsRegistered(email)
    if (isRegistered) return history.push(`/login?email=${email}`)

    if (await getIsEmailVerified(email))
      return onEmailVerified()

    const { success, message } = await sendVerifyEmail(email, wallet, captcha, fiatAmount, fiatCurrency, cryptoCurrency)
    if (!success) return toast.error(message)
    else return setIsShowVerifyEmail(true)
  }

  const onEmailVerified = () => {
    setIsShowVerifyEmail(false)
    history.push(cfgExchange.STEPS.STEP2.route)
  }

  const captchaRef = useRef(null)
  const onCaptchaChange = (value: string) => {
    setValue('captcha', value, { shouldValidate: true })
  }

  const onEmailVerifyHide = () => {
    setIsShowVerifyEmail(false)
    captchaRef.current.reset()
    setValue('captcha', null, { shouldValidate: true })
  }

  return {
    isAuthorized,
    isShowVerifyEmail,
    formMethods,
    onSubmit,
    onEmailVerified,
    onEmailVerifyHide,
    captchaRef,
    onCaptchaChange,
    onSelectPayment,
    step1Values,
    paymentTypes,
    selectFiat,
    selectCrypto,
    hasWallet,
    paymentSelected,
  }
}
