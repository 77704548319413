import * as Urql from "urql";
import { AnyVariables } from "urql";

export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type CardListFilterData = {
  status: CardStatus[];
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Id: any;
  Id32: any;
  Time: any;
};

export enum DeliveryType {
  DhlExpress = "DHLExpress",
  DhlGlobalMail = "DHLGlobalMail",
}

export type QueryType<Q extends object, T extends object, V = AnyVariables> = (
  options: Omit<Urql.UseQueryArgs<T>, "query">
) => Urql.UseQueryResponse<Q, V>;

export type MutationType<
  T extends object,
  V = AnyVariables
> = Urql.UseMutationResponse<T, V>;

export enum CardStatus {
  Active = "Active",
  AwaitingRenewal = "AwaitingRenewal",
  Blocked = "Blocked",
  Closed = "Closed",
  Closing = "Closing",
  Completed = "Completed",
  Created = "Created",
  Dispatched = "Dispatched",
  Expired = "Expired",
  Ordered = "Ordered",
  Personalized = "Personalized",
}

export enum CardTransactionStatus {
  Canceled = "Canceled",
  Completed = "Completed",
  Pending = "Pending",
}

export enum CurrencyCode {
  Eur = "EUR",
}
export enum CountryCode {
  Abw = "ABW",
  Afg = "AFG",
  Ago = "AGO",
  Aia = "AIA",
  Ala = "ALA",
  Alb = "ALB",
  And = "AND",
  Are = "ARE",
  Arg = "ARG",
  Arm = "ARM",
  Asm = "ASM",
  Ata = "ATA",
  Atf = "ATF",
  Atg = "ATG",
  Aus = "AUS",
  Aut = "AUT",
  Aze = "AZE",
  Bdi = "BDI",
  Bel = "BEL",
  Ben = "BEN",
  Bes = "BES",
  Bfa = "BFA",
  Bgd = "BGD",
  Bgr = "BGR",
  Bhr = "BHR",
  Bhs = "BHS",
  Bih = "BIH",
  Blm = "BLM",
  Blr = "BLR",
  Blz = "BLZ",
  Bmu = "BMU",
  Bol = "BOL",
  Bra = "BRA",
  Brb = "BRB",
  Brn = "BRN",
  Btn = "BTN",
  Bvt = "BVT",
  Bwa = "BWA",
  Caf = "CAF",
  Can = "CAN",
  Cck = "CCK",
  Che = "CHE",
  Chl = "CHL",
  Chn = "CHN",
  Civ = "CIV",
  Cmr = "CMR",
  Cod = "COD",
  Cog = "COG",
  Cok = "COK",
  Col = "COL",
  Com = "COM",
  Cpv = "CPV",
  Cri = "CRI",
  Cub = "CUB",
  Cuw = "CUW",
  Cxr = "CXR",
  Cym = "CYM",
  Cyp = "CYP",
  Cze = "CZE",
  Deu = "DEU",
  Dji = "DJI",
  Dma = "DMA",
  Dnk = "DNK",
  Dom = "DOM",
  Dza = "DZA",
  Ecu = "ECU",
  Egy = "EGY",
  Eri = "ERI",
  Esh = "ESH",
  Esp = "ESP",
  Est = "EST",
  Eth = "ETH",
  Fin = "FIN",
  Fji = "FJI",
  Flk = "FLK",
  Fra = "FRA",
  Fro = "FRO",
  Fsm = "FSM",
  Gab = "GAB",
  Gbr = "GBR",
  Geo = "GEO",
  Ggy = "GGY",
  Gha = "GHA",
  Gib = "GIB",
  Gin = "GIN",
  Glp = "GLP",
  Gmb = "GMB",
  Gnb = "GNB",
  Gnq = "GNQ",
  Grc = "GRC",
  Grd = "GRD",
  Grl = "GRL",
  Gtm = "GTM",
  Guf = "GUF",
  Gum = "GUM",
  Guy = "GUY",
  Hkg = "HKG",
  Hmd = "HMD",
  Hnd = "HND",
  Hrv = "HRV",
  Hti = "HTI",
  Hun = "HUN",
  Idn = "IDN",
  Imn = "IMN",
  Ind = "IND",
  Iot = "IOT",
  Irl = "IRL",
  Irn = "IRN",
  Irq = "IRQ",
  Isl = "ISL",
  Isr = "ISR",
  Ita = "ITA",
  Jam = "JAM",
  Jey = "JEY",
  Jor = "JOR",
  Jpn = "JPN",
  Kaz = "KAZ",
  Ken = "KEN",
  Kgz = "KGZ",
  Khm = "KHM",
  Kir = "KIR",
  Kna = "KNA",
  Kor = "KOR",
  Kwt = "KWT",
  Lao = "LAO",
  Lbn = "LBN",
  Lbr = "LBR",
  Lby = "LBY",
  Lca = "LCA",
  Lie = "LIE",
  Lka = "LKA",
  Lso = "LSO",
  Ltu = "LTU",
  Lux = "LUX",
  Lva = "LVA",
  Mac = "MAC",
  Maf = "MAF",
  Mar = "MAR",
  Mco = "MCO",
  Mda = "MDA",
  Mdg = "MDG",
  Mdv = "MDV",
  Mex = "MEX",
  Mhl = "MHL",
  Mkd = "MKD",
  Mli = "MLI",
  Mlt = "MLT",
  Mmr = "MMR",
  Mne = "MNE",
  Mng = "MNG",
  Mnp = "MNP",
  Moz = "MOZ",
  Mrt = "MRT",
  Msr = "MSR",
  Mtq = "MTQ",
  Mus = "MUS",
  Mwi = "MWI",
  Mys = "MYS",
  Myt = "MYT",
  Nam = "NAM",
  Ncl = "NCL",
  Ner = "NER",
  Nfk = "NFK",
  Nga = "NGA",
  Nic = "NIC",
  Niu = "NIU",
  Nld = "NLD",
  Nor = "NOR",
  Npl = "NPL",
  Nru = "NRU",
  Nzl = "NZL",
  Omn = "OMN",
  Pak = "PAK",
  Pan = "PAN",
  Pcn = "PCN",
  Per = "PER",
  Phl = "PHL",
  Plw = "PLW",
  Png = "PNG",
  Pol = "POL",
  Pri = "PRI",
  Prk = "PRK",
  Prt = "PRT",
  Pry = "PRY",
  Pse = "PSE",
  Pyf = "PYF",
  Qat = "QAT",
  Reu = "REU",
  Rou = "ROU",
  Rus = "RUS",
  Rwa = "RWA",
  Sau = "SAU",
  Sdn = "SDN",
  Sen = "SEN",
  Sgp = "SGP",
  Sgs = "SGS",
  Shn = "SHN",
  Sjm = "SJM",
  Slb = "SLB",
  Sle = "SLE",
  Slv = "SLV",
  Smr = "SMR",
  Som = "SOM",
  Spm = "SPM",
  Srb = "SRB",
  Ssd = "SSD",
  Stp = "STP",
  Sur = "SUR",
  Svk = "SVK",
  Svn = "SVN",
  Swe = "SWE",
  Swz = "SWZ",
  Sxm = "SXM",
  Syc = "SYC",
  Syr = "SYR",
  Tca = "TCA",
  Tcd = "TCD",
  Tgo = "TGO",
  Tha = "THA",
  Tjk = "TJK",
  Tkl = "TKL",
  Tkm = "TKM",
  Tls = "TLS",
  Ton = "TON",
  Tto = "TTO",
  Tun = "TUN",
  Tur = "TUR",
  Tuv = "TUV",
  Twn = "TWN",
  Tza = "TZA",
  Uga = "UGA",
  Ukr = "UKR",
  Umi = "UMI",
  Unk = "UNK",
  Ury = "URY",
  Usa = "USA",
  Uzb = "UZB",
  Vat = "VAT",
  Vct = "VCT",
  Ven = "VEN",
  Vgb = "VGB",
  Vir = "VIR",
  Vnm = "VNM",
  Vut = "VUT",
  Wlf = "WLF",
  Wsm = "WSM",
  Yem = "YEM",
  Zaf = "ZAF",
  Zmb = "ZMB",
  Zwe = "ZWE",
}
