import {Verification} from "./ExClient";

export class ExClientInfo {
    id:number
    first_name: string = "";
    last_name: string = "";
    address: string = "";
    email: string = "";
    phone: string = "";
    country_code: string = "";
    city: string = "";
    zip: string = "";
    state: string = "";
    date_created: Date;
    date_birth: string = "";
    sex: number
    verify_flags: number
    avatar: string = null

    getFormatedDateBirth(): string
    {
        return this.date_birth.split(' ')[0];
    }

    getSexTitle(): string
    {
        return this.sex == 1 ? "Male" : "Female";
    }

    static checkVerified(client: ExClientInfo): boolean
    {
        return  !!((client.verify_flags & Verification.VERIFICATION_SELFIE_CARD_EXCLIENT) && (client.verify_flags & Verification.VERIFICATION_PASSPORT_EXCLIENT))
    }
}
