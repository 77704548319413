export const VALID_NAME = {
  minLength: {
    value: 2,
    message: 'The minimum field length 2 symbols.',
  },
  pattern: {
    value: /^[a-zA-Z\s]*$/,
    message: 'Only Latin letters are allowed.',
  }
}

export const VALID_EMAIL = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Invalid email address.'
  }
}

export const VALID_PHONE = {
  minLength: {
    value: 6,
    message: 'The minimum field length 6 digits.',
  },
  pattern: {
    value: /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#](\d+))?\s*$/,
    message: 'Invalid phone number.',
  }
}

export const VALID_PASSWORD = {
  minLength: {
    value: 6,
    message: 'The minimum field length 6 symbols.',
  },
}
