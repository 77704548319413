import {DefaultValues} from "../DefaultValues";
import $ from 'jquery';
import { checkAuth } from '../ApiData'
import { LOCALE_KEY_NAME } from '../constants/CommonConstants'

export abstract class NetUtils {

    static getApiDataPromiseResultWithRetry = async (body: any, method: string, action: string, stage: number = 0): Promise<any> => {
        try {
            body.lang = (localStorage.getItem(LOCALE_KEY_NAME) || 'en-US').substring(0, 2)
            body.action = action;
            let response = await fetch(
                DefaultValues.PAY + 'api/' + method + '.htm',
                {
                    method: 'post',
                    headers:new Headers({
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }),
                    body: $.param(body),
                    credentials: "include"
                });

            const response_json = await response.json();
            checkAuth(response_json)

            if(response_json['success'])
                return response_json['data'];

        } catch(e) {
            console.log("Error", e);
            if(stage == 5)
                return false;
            await NetUtils.delay(250);
            return await NetUtils.getApiDataPromiseResultWithRetry(body, method, action, stage + 1);
        }

        return false;
    };

    static delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
