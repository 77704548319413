import { useEffect } from 'react'
import { History } from 'history'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ExClient } from '../ExClient'
import { ExceptionCodes } from '../ApiData'
import { useStoreMain } from '../store/useStoreMain'
import { selectSetIsAuthorized } from '../store/selectors/MainSelectors'
import { cfgExchange } from '../DefaultValues'
import { UrlUtils } from '../utils/UrlUtils'

type LoginFormProps = {
  history: History
  msgConfirmIP?: string
  msgConfirmEmail?: string
  msgVerifySent?: string
  msgUnusualActivity?: string
}

type LoginFormReturn = {
  formMethods: UseFormMethods<{ email: string }>
  onSubmit: (data: any) => void
}

export const useLoginForm = <TFormValues extends Record<string, any> = Record<string, any>>({
  history,
  msgConfirmIP = 'Your IP has been verified!',
  msgConfirmEmail = 'Your Email has been verified!',
  msgVerifySent = 'Verification email was sent to your email!',
  msgUnusualActivity = 'Unusual activity detected. Verify your IP. Instructions were sent to your email.'
}: LoginFormProps): LoginFormReturn => {
  const setIsAuthorized = useStoreMain(selectSetIsAuthorized)
  const email = UrlUtils.getUrlParam('email')
  const formMethods = useForm<{ email: string }>({ defaultValues: { email } })

  useEffect(() => {
    switch (location.hash) {
      case '#success_confirm_ip':
        toast.info(msgConfirmIP)
        break

      case '#success_confirm_email':
        toast.info(msgConfirmEmail)
        break
    }
  }, [])

  const onSubmit = async function (data: any) {
    try {
      const success = await ExClient.login(data.email, data.password)
      if (!success) return

      if (email && email !== '') {
        setIsAuthorized(true)
        history.push(`/exchange/${cfgExchange.STEPS.STEP1.route}`)
      } else ExClient.redirectLogin()
    } catch (e) {
      if (e.code === ExceptionCodes.VERIFICATION_EMAIL_CODE) {
        toast.info(msgVerifySent)
      } else if (e.code === ExceptionCodes.NO_AUTHORIZATION_NOT_ALLOWED_IP_CODE) {
        toast.error(msgUnusualActivity)
      } else toast.error(e.message)
    }
  }

  return {
    formMethods,
    onSubmit,
  }
}
