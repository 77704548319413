import { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ApiData } from '../ApiData'

type FormProps = {
  email: string
}

type RecoveryFormReturn = {
  formMethods: UseFormMethods<FormProps>
  onSubmit: (data: FormProps) => void
  email: string
  setEmail: (value: string) => void
}

export const useRecoveryForm = <TFormValues extends Record<string, any> = Record<string, any>>(): RecoveryFormReturn => {
  const [email, setEmail] = useState(null)

  const formMethods = useForm<FormProps>()
  const onSubmit = async (data: FormProps) => {
    const { email } = data
    const { success, message } = await ApiData.clientApiRequest({ email }, 'reset_password_email', null)
    if (!success)
      return toast.error(message)

    setEmail(email)
    formMethods.reset()
  }

  return {
    formMethods,
    onSubmit,
    email,
    setEmail,
  }
}
