import React, { useCallback, useEffect, useRef, useState } from 'react'
import { History } from 'history'
import { toast } from 'react-toastify'
import { UrlUtils } from '../utils/UrlUtils'
import { useStoreExchange } from '../store/useStoreExchange'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import { selectSetStep4, selectStep1, selectStep4 } from '../store/selectors/ExchangePageSelectors'
import { selectCryptoCurrency, selectFiatAmount, selectFiatCurrency } from '../store/selectors/ExchangeSelectors'
import { createTransaction } from '../store/apiRequests/exchangeRequests'
import { cfgExchange, DefaultValues } from '../DefaultValues'
import { LOCALE_KEY_NAME } from '../constants/CommonConstants'

type ExchangeStep4Props = {
  history: History
}

type ExchangeStep4Return = {
  token: string
  form: {
    url: string
    params: any[]
  }
  formEl: React.MutableRefObject<any>
  iframeSrc: string
}

export const useExchangeStep4 = ({ history }: ExchangeStep4Props): ExchangeStep4Return => {
  const formEl = useRef(null)
  const { token, payment_system_id } = useStoreExchangePage(selectStep4)
  const { paymentType, wallet, tryAgain } = useStoreExchangePage(selectStep1)
  const setStep4 = useStoreExchangePage(selectSetStep4)
  const fiatAmount = useStoreExchange(selectFiatAmount)
  const fiatCurrency = useStoreExchange(selectFiatCurrency)
  const cryptoCurrency = useStoreExchange(selectCryptoCurrency)

  const iframeSrc = DefaultValues.SECURE
    + `?token=${token}`
    + `&p_id=${payment_system_id}`
    + `&type=2`
    + `&name=${(paymentType.name === 'maestro' ? 'mastercard' : paymentType.name)}`
    + `&lang=${(localStorage.getItem(LOCALE_KEY_NAME) || 'en-US').substring(0, 2)}`

  const [form, setForm] = useState({
    url: '#',
    params: null,
  })

  useEffect(() => {
    const createTrans = async () => {
      const { success, message, data } = await createTransaction(fiatAmount, fiatCurrency, cryptoCurrency, wallet, tryAgain)
      if (!success) {
        toast.error(message)
        return history.push(cfgExchange.STEPS.STEP1.route)
      }

      const { token, self_processing } = data
      localStorage.setItem('wallet', wallet)
      localStorage.setItem('payment_token', token)

      if (!self_processing)
        return UrlUtils.redirectTo(`${DefaultValues.PAY}payments/checkout.htm?token=${token}`)

      setStep4({ ...data })
    }
    createTrans().then()
  }, [fiatAmount, fiatCurrency, cryptoCurrency, wallet, setStep4, history, tryAgain])

  const onSecureMessage = useCallback((e: any) => {
    const { data: { success, type, data }, origin } = e
    if (DefaultValues.SECURE.indexOf(origin) === -1)
      return

    if (!success)
      return toast.error('Error while processing.')

    switch (type) {
      case 'form':
        if (data.type === 'GET') {
          UrlUtils.redirectTo(data.url)
        } else {
          setForm(data)
          formEl.current.submit()
        }
        break

      case 'simple':
        history.push(cfgExchange.STEPS.STEP5.route)
        break

      case 'error':
        toast.error(data.message)
        break
    }
  }, [history])

  useEffect(() => {
    window.addEventListener('message', onSecureMessage, false)
    return () => window.removeEventListener('message', onSecureMessage, false)
  }, [onSecureMessage])

  return {
    token,
    form,
    formEl,
    iframeSrc,
  }
}
