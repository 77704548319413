import { useEffect, useState } from 'react'
import { ApiData } from '../ApiData'
import { UrlUtils } from '../utils/UrlUtils'

type InfoPageReturn = {
  code: number
  message: string
}

export const useInfoPage = (): InfoPageReturn => {
  const [code, setCode] = useState(0)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const getMessage = async () => {
      const code = Number(UrlUtils.getUrlParam('code'))
      if (!isNaN(code)) {
        setCode(code)

        const { success, data } = await ApiData.publicApiRequest({ code }, 'get_error_message_by_code')
        if (success) setMessage(data)
      } else UrlUtils.redirectTo('/')
    }
    getMessage().then()
  }, [])

  return {
    code,
    message,
  }
}
