import create, { State } from 'zustand'
import { getApiDataPromise } from '../ApiData'

export type exchangeApiResponseData = {
  symbol: string
  rate: number
  title: string
  change: number | string
}[]

interface state extends State {
  cryptoDataList: exchangeApiResponseData
  fiatDataList: exchangeApiResponseData
  cryptoSelectedSymbol: string
  fiatSelectedSymbol: string
  loadExchangeRatesCrypto: (symbol: string) => void
  loadExchangeRatesFiat: (symbol: string) => void
}

export const useStoreExchangeRates = create<state>((set) => ({
  cryptoDataList: [],
  fiatDataList: [],
  cryptoSelectedSymbol: '',
  fiatSelectedSymbol: '',

  loadExchangeRatesCrypto: async (symbol) => {
    const { success, data } = await getApiDataPromise({ symbol }, 'exchange', 'get_rates_fiat_symbol')
    if (success) set({ cryptoDataList: data, cryptoSelectedSymbol: symbol })
  },

  loadExchangeRatesFiat: async (symbol) => {
    const { success, data } = await getApiDataPromise({ symbol }, 'exchange', 'get_rates_crypto_symbol')
    if (success) set({ fiatDataList: data, fiatSelectedSymbol: symbol })
  },
}))
