import { DefaultValues } from "./DefaultValues";
import $ from "jquery";
import { CLIENT_EVENTS, EventUtils } from "./utils/EventUtils";
import { LOCALE_KEY_NAME } from "./constants/CommonConstants";

export interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}

export enum ExceptionCodes {
  VERIFICATION_UNKNOWN_CODE = 4000,
  VERIFICATION_EMAIL_CODE = 4001,
  VERIFICATION_PHONE_CODE = 4002,

  NO_AUTHORIZATION_CODE = 5000,
  NO_AUTHORIZATION_NOT_ALLOWED_IP_CODE = 5001,

  CAPTCHA_CODE = 6000,
}

export const http = <T>(
  request: Request | string
): Promise<IHttpResponse<T>> => {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request)
      .then((res) => {
        response = res;
        return res.json();
      })
      .then((body) => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export interface IApiResponse<T = any> {
  success: boolean;
  data: T;
  message: string;
  code: number;
}

export enum EDepositStatus {
  Pending = "Exchange Pending",
  Received = "Received Crypto",
  ExCompleted = "Exchange Completed",
  Charged = "Card Charged",
  Error = "Error",
}

export interface IDepositAddress {
  timestamp: number;
  wallet: string;
}
export interface IDepositAddressParams {
  card_id?: string;
  fiat_amount: number;
  fiat_currency: string;
  crypto_amount: number;
  crypto_currency: string;
}

export interface ICreatePaymentResponse {
  token: string;
  timestamp: number;
  transaction_id: number;
  ex_transaction_id: number;
}
export interface IDepositStatus {
  method: string;
  refid: string;
  status: EDepositStatus;
  txid: string;
  timestamp: string;
  confirmation: number;
}
export const getApiDataPromise = async <T = any>(
  body: any,
  method: string,
  action: string
): Promise<IApiResponse<T>> => {
  try {
    body.lang = (localStorage.getItem(LOCALE_KEY_NAME) || "en-US").substring(
      0,
      2
    );
    body.action = action;
    let response = await fetch(DefaultValues.PAY + "api/" + method + ".htm", {
      method: "post",
      headers: new Headers({
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      }),
      body: $.param(body),
      credentials: "include",
    });

    if (!response) return { success: false, data: null, message: "", code: 0 };

    const responseJson = await response.json();
    checkAuth(responseJson);

    return responseJson;
  } catch (response) {
    console.log("Error", response);
    return { success: false, data: null, message: "", code: 0 };
  }
};

export class ApiResponse {
  success = true;
  data: any;
  message = "";
  code = 0;
}

export class ApiError extends Error {
  code: number;

  constructor(message?: string, code?: number) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = "ApiError";
    this.code = code;
  }
}

type getSelectorListResponseData = {
  key: "USD" | "EUR" | string;
  value: string /* "number" */;
}[];

export abstract class ApiData {
  public static async clientApiRequest(
    body: any,
    action: string,
    page = ""
  ): Promise<IApiResponse> {
    const method = page == null ? "client" : "exclient/" + page;
    return await getApiDataPromise(body, method, action);
  }

  public static async exchangeApiRequest(
    body: any,
    action: string
  ): Promise<ApiResponse> {
    return await getApiDataPromise(body, "exchange", action);
  }

  public static async publicApiRequest(
    body: any,
    action: string
  ): Promise<IApiResponse> {
    return await getApiDataPromise(body, "public_data", action);
  }

  public static async getSelectorList(
    name: string,
    body: any = {},
    useCache = true
  ): Promise<getSelectorListResponseData> {
    const key = `${name}_kv_list`;
    const cachedData = localStorage.getItem(key);
    if (useCache && cachedData) return JSON.parse(cachedData);

    const { success, data } = await this.publicApiRequest(body, `get_${key}`);
    if (!success) return [];

    if (useCache) localStorage.setItem(key, JSON.stringify(data));
    return data;
  }

  public static async getExchangeRates(
    action: string = "get_currencies_exchange"
  ): Promise<IApiResponse> {
    return await this.exchangeApiRequest({}, action);
  }

  public static async getCurrenciesExchange(): Promise<any[any]> {
    const { success, data } = await this.getExchangeRates();
    return success ? data : [];
  }
  public static async getDepositAddress(
    params: IDepositAddressParams
  ): Promise<IApiResponse<IDepositAddress>> {
    const result = await getApiDataPromise(
      params,
      "exchange",
      "deposit_address"
    );
    return result;
  }
  public static async createDeposit(
    params: IDepositAddressParams
  ): Promise<IApiResponse<ICreatePaymentResponse>> {
    const result = await getApiDataPromise(params, "exchange", "deposit");
    return result;
  }
  public static async getStatusDeposit(
    token: string
  ): Promise<IApiResponse<IDepositStatus>> {
    const result = await getApiDataPromise(
      { token },
      "exchange",
      "deposit_check"
    );
    return result;
  }
}

export const checkAuth = (response: any) => {
  if (typeof response !== "object") return;

  const code = parseInt(response.code);
  if (isNaN(code) || code !== 5000) return;
  EventUtils.bus.publish(CLIENT_EVENTS.clientLoggedOutEvent(null));
};
