import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ApiData } from '../ApiData'
import { SOMETHING_WRONG } from '../constants/CommonConstants'

type ExchangeStep5CartReturn = {
  info: {
    crypto_amount: string
    crypto_currency: string
    fiat_total_amount: string
    fiat_amount: string
    fiat_currency: string
    transaction_fee_pct: number
    ex_transaction_id: number
    rate: number
  }
}

export const useExchangeStep5Cart = (): ExchangeStep5CartReturn => {
  const [info, setInfo] = useState({
    crypto_amount: null,
    crypto_currency: '',
    fiat_total_amount: null,
    fiat_amount: null,
    fiat_currency: '',
    transaction_fee_pct: null,
    ex_transaction_id: null,
    rate: 0
  })

  useEffect(() => {
    const fetchTransaction = async () => {
      const res = await ApiData.exchangeApiRequest({
        payment_token: localStorage.getItem('payment_token')
      }, 'get_transaction_and_update')

      if (res === null) return toast.error(SOMETHING_WRONG)

      const { success, message, data } = res
      if (!success) return toast.error(message)
      setInfo(data)
    }
    fetchTransaction().then()
  }, [])

  return {
    info
  }
}
