import React, { useEffect, useState } from 'react'
import { History } from 'history'
import { toast } from 'react-toastify'
import { useForm, UseFormMethods } from 'react-hook-form'
import { ExClient } from '../ExClient'
import { useStoreMain } from '../store/useStoreMain'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import { getIsAuthorized } from '../store/selectors/MainSelectors'
import { selectSetStep3, selectStep3 } from '../store/selectors/ExchangePageSelectors'
import { cfgExchange } from '../DefaultValues'
import { ExchangeStep3Values } from '../types/ExchangeTypes'

interface FormInputs {
  photo_file: string
  passport_file: string
  agree_terms: boolean
  agree_age: boolean
}

type ExchangeStep3Props = {
  history: History
}

type ExchangeStep3Return = {
  formMethods: UseFormMethods<FormInputs>
  onSubmit: (data: FormInputs) => void
  onSkipConfirm: () => void
  passport: File
  photo: File
  setStep3: (values: ExchangeStep3Values) => void
  showSkip: boolean
  setShowSkip: React.Dispatch<React.SetStateAction<boolean>>
  isDefaultShop: boolean
  isDocsUploaded: boolean
}

export const useExchangeStep3 = <TFormValues extends Record<string, any> = Record<string, any>>({
  history
}: ExchangeStep3Props): ExchangeStep3Return => {
  const isAuthorized = useStoreMain(getIsAuthorized)
  const { passport, photo } = useStoreExchangePage(selectStep3)
  const setStep3 = useStoreExchangePage(selectSetStep3)
  const [showSkip, setShowSkip] = useState(false)
  const [isDefaultShop, setIsDefaultShop] = useState(false)
  const [isDocsUploaded, setIsDocsUploaded] = useState(false)

  useEffect(() => {
    if (!isAuthorized)
      return

    const checkIfVerified = async () => {
      const { verify_flags, is_verified, is_default_shop, main_docs_is_uploaded } = await ExClient.getVerifyInfo()

      setIsDefaultShop(is_default_shop)
      setIsDocsUploaded(main_docs_is_uploaded)

      if (ExClient.isVerified(verify_flags, is_verified, is_default_shop)) {
        history.push(cfgExchange.STEPS.STEP4.route)
      }
    }
    checkIfVerified().then()
  }, [history, isAuthorized])

  const formMethods = useForm<FormInputs>()

  const onSubmit = async (data: FormInputs) => {
    const { success: passportSuccess, message: passportMsg } = await ExClient.uploadDocument(passport, '1')
    if (!passportSuccess)
      return toast.error(passportMsg)

    const { success: photoSuccess, message: photoMsg } = await ExClient.uploadDocument(photo, '5')
    if (!photoSuccess)
      return toast.error(photoMsg)

    setIsDocsUploaded(true)
    if (!isDefaultShop)
      history.push(cfgExchange.STEPS.STEP4.route)
  }

  const onSkipConfirm = () => {
    setStep3({ photo: undefined, passport: undefined })
    history.push(cfgExchange.STEPS.STEP4.route)
  }

  return {
    formMethods,
    onSubmit,
    onSkipConfirm,
    passport,
    photo,
    setStep3,
    showSkip,
    setShowSkip,
    isDefaultShop,
    isDocsUploaded,
  }
}
